<template>
  <FileUploadComponent :uploaded="value" @change="$emit('change', $event)" />
</template>

<script>
import FileUploadComponent from "components/inputs/FileUploadComponent.vue";

export default {
  name: "CourseFileUploadWrapper",
  components: { FileUploadComponent },
  props: {
    value: {
      type: Array,
    },
  },
};
</script>
