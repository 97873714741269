<template>
  <div class="course-aside">
    <span class="course-aside__title">Список уроков из модуля</span>
    <div class="course-aside__list">
      <router-link
        :to="{ name: 'account_education_open', params: { module: $route.params.module, lesson: item.id } }"
        class="course-aside__item"
        :class="{ 'course-aside__item--active': item.id === active.id }"
        v-for="(item, i) in list"
        :key="i"
      >
        <CourseTestIcon class="course-aside__play" v-if="item.passingTest" />
        <CoursePlayIcon class="course-aside__play" v-else />
        <div class="course-aside__item-info">
          <span class="course-aside__item-title">{{ item.title }}</span>
          <span class="course-aside__item-subtitle">{{ item.subtitle }}</span>
        </div>
        <IconComponent name="GreenCheckIcon" class="course-aside__check" v-if="item.check" />
      </router-link>
    </div>
  </div>
</template>

<script>
import CoursePlayIcon from "../components/svg/CoursePlayIcon.vue";
import CourseTestIcon from "../components/svg/CourseTestIcon.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "CourseAsideComponent",
  props: {
    active: {
      type: Object,
      default() {
        return 0;
      },
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: { IconComponent, CourseTestIcon, CoursePlayIcon },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.course-aside {
  border-right 1px solid var(--border_color)
  width 100%
  height 100%
  display flex
  flex-direction column
  padding-bottom 30px

  &__title {
    width 100%
    padding 10px 20px 20px
    font-weight: 500;
    font-size: 1.125em;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: var(--black);
    text-align left
    border-bottom 1px solid var(--border_color)
  }

  &__list {
    display grid
    width 100%
  }

  &__item {
    display grid
    grid-template-columns 24px 1fr 24px
    grid-gap 10px
    padding 16px 20px 14px
    border-right 2px solid transparent
    margin-right -1px
    border-bottom 1px solid var(--border_color)
    width calc(100% + 1px)

    &:hover {
      border-bottom-color var(--border_color)
    }

    &--active {
      border-right-color var(--main_color)

      &:hover {
        border-right-color var(--main_color)
      }

      ^[0]__play {
        svg(var(--main_color))
      }
    }
  }

  &__item-info {
    display grid
    grid-gap 1px
    align-items start
    justify-content start
    width 100%
  }

  &__item-title {
    font-weight: 500;
    font-size: 1em;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: var(--black);
  }

  &__item-subtitle {
    font-size: 0.875em;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: var(--black);
    opacity: 0.5;
  }

  &__play {
    width 24px
    height 24px
    margin-bottom auto

    svg {
      width 100%
      height 100%
      svg(var(--gray-2))
    }
  }

  &__check {
    width 24px
    height 24px
    margin-bottom auto

    svg {
      width 100%
      height 100%

      circle {
        fill var(--main_color)
      }
    }
  }
}
</style>
