<template>
  <figure class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.52047 3.97803L4.47447 6.02203L3.48047 5.03103"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M10.9531 3H17.0001" stroke="#323232" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11 6H21" stroke="#323232" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M6.52047 10.978L4.47447 13.022L3.48047 12.031"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M10.9531 10H17.0001" stroke="#323232" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11 13H21" stroke="#323232" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21.0001 20H10.9531" stroke="#323232" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M21 17H11" stroke="#323232" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M5 21C6.10457 21 7 20.1046 7 19C7 17.8954 6.10457 17 5 17C3.89543 17 3 17.8954 3 19C3 20.1046 3.89543 21 5 21Z"
        stroke="#323232"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "CourseTestIcon",
};
</script>
