<template>
  <div class="module-open__container">
    <CoursePageHeader
      :title="lesson.title"
      :haveNext="haveNext"
      :havePrev="havePrev"
      :passingTest="passingTest"
      :showTypeToggle="showTypeToggle"
      @change="$emit('change', $event)"
    />
    <div class="course-tests">
      <CourseTestComponent :order="i + 1" :key="i" :title="test.title" v-for="(test, i) in testsForm">
        <Component :is="test.component" v-bind="test.props" @change="setValueFromChange($event, test, i)" />
      </CourseTestComponent>
    </div>
    <button type="button" class="btn btn--sm btn--main" @click="submitTest" :disabled="loading">
      <LoadingIndicator v-if="loading" />
      <template v-else>Получить результаты</template>
    </button>
  </div>
</template>

<script>
import CourseTestComponent from "components/inputs/test/index.vue";
import CourseCheckboxList from "./components/inputs/CourseCheckboxList.vue";
import CourseRadioList from "./components/inputs/CourseRadioList.vue";
import CourseFileUploadWrapper from "./components/inputs/CourseFileUploadWrapper.vue";
import CoursePageHeader from "./components/CoursePageHeader.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";
import TAKING_TEST from "gql/mutations/TakingTest.graphql";
import AlertModal from "components/modals/components/AlertModal.vue";
import TextareaComponent from "components/inputs/TextareaComponent.vue";

export default {
  name: "CourseTestPage",
  props: {
    havePrev: Boolean,
    haveNext: Boolean,
    showTypeToggle: Boolean,
    passingTest: Boolean,
    lesson: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      testsForm: [],
    };
  },
  computed: {
    types() {
      return this.$store.state._types;
    },
    passing_score() {
      return this.$store.state.tmp.passing_score;
    },
  },
  created() {
    this.testsForm = [];
    this.lesson.tests.forEach((test, index) => {
      switch (test.type.code) {
        case this.types.CODE_TEST_TEXT_INPUT:
          this.testsForm.push(this.getFormattedTestInput(test, index));
          break;
        case this.types.CODE_TEST_SELECTING_ONE:
          this.testsForm.push(this.getFormattedTestSelectOne(test, index));
          break;
        case this.types.CODE_TEST_SELECTING_FEW:
          this.testsForm.push(this.getFormattedTestSelectFew(test, index));
          break;
        case this.types.CODE_TEST_FILE_UPLOAD:
          this.testsForm.push(this.getFormattedTestUpload(test, index));
          break;
      }
    });
  },
  methods: {
    resetErrors() {
      this.testsForm.forEach((item) => {
        item.props.errors = [];
      });
    },
    submitTest() {
      if (!this.loading) {
        this.loading = true;
        this.resetErrors();
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: TAKING_TEST,
            variables: {
              input: this.testsForm.map((test) => {
                let answer_id = null;
                let answer = null;
                if (test.type === this.types.CODE_TEST_SELECTING_ONE) {
                  answer_id = test.props.value ? [test.props.value.id] : null;
                }
                if (test.type === this.types.CODE_TEST_SELECTING_FEW) {
                  answer_id = test.props.value.length ? test.props.value.map((v) => v.id) : null;
                }
                if (test.type === this.types.CODE_TEST_TEXT_INPUT) {
                  answer = test.props.value;
                }
                return {
                  test_id: test.id,
                  answer_id: answer_id,
                  answer: answer,
                };
              }),
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            if (data && data.TakingTest) {
              let points = data.TakingTest.map((test) => test.point).reduce((a, b) => a + b, 0);
              let maxPoints = data.TakingTest.map((test) => test.max_point).reduce((a, b) => a + b, 0);
              let isPassed = false;
              if (points >= this.passing_score) {
                isPassed = true;
              }
              let options = {
                type: isPassed ? "success" : "error",
                title: isPassed ? "Тест пройден успешно!" : "Тест не пройден",
                subtitle: `Вы набрали ${points} из ${maxPoints} баллов.`,
                buttonTitle: isPassed ? "Готово" : "Попробовать ещё раз",
                point: this.passing_score,
                callback: () => {
                  if (isPassed) {
                    this.$store.state._modals = [];
                  } else {
                    this.resetForm();
                  }
                },
              };
              this.$store.state._modals.push({
                component: AlertModal,
                options: options,
              });
            }
            this.loading = false;
          })
          .catch(({ graphQLErrors }) => {
            this.$notify({
              title: "Ошибка",
              text: "Произошла ошибка во время обработки теста",
              duration: 8000,
              speed: 200,
              type: "error",
            });
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    resetForm() {
      this.testsForm.forEach((test) => {
        test.props.value = test.props.defaultValue;
      });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            let arr = key.split(".");
            let [parent, index, child] = arr;
            this.testsForm.forEach((item) => {
              if (item.index === parseInt(index)) {
                item.props.errors.push(...err.extensions.validation[key]);
              }
            });
          });
        }
      });
    },
    getFormattedTestInput(test, index) {
      return {
        id: test.id,
        index: index,
        type: this.types.CODE_TEST_TEXT_INPUT,
        title: test.text,
        component: TextareaComponent,
        props: {
          placeholder: "Введите ваш ответ",
          rows: 10,
          value: null,
          defaultValue: null,
          errors: [],
        },
      };
    },
    getFormattedTestSelectOne(test, index) {
      return {
        id: test.id,
        index: index,
        type: this.types.CODE_TEST_SELECTING_ONE,
        title: test.text,
        component: CourseRadioList,
        props: {
          list: test.test_answer.map((answer) => ({
            id: answer.id,
            title: answer.answer,
          })),
          value: null,
          defaultValue: null,
          errors: [],
        },
      };
    },
    getFormattedTestSelectFew(test, index) {
      return {
        id: test.id,
        index: index,
        type: this.types.CODE_TEST_SELECTING_FEW,
        title: test.text,
        component: CourseCheckboxList,
        props: {
          list: test.test_answer.map((answer) => ({
            id: answer.id,
            title: answer.answer,
          })),
          value: [],
          defaultValue: [],
          errors: [],
        },
      };
    },
    getFormattedTestUpload(test, index) {
      return {
        id: test.id,
        index: index,
        type: this.types.CODE_TEST_FILE_UPLOAD,
        title: test.text,
        component: CourseFileUploadWrapper,
        props: {
          value: [],
          defaultValue: [],
          errors: [],
        },
      };
    },
    toggleFromArray(array, item) {
      array = array.map((i) => JSON.stringify(i));
      if (array.indexOf(JSON.stringify(item)) === -1) {
        array.push(JSON.stringify(item));
      } else {
        array.splice(array.indexOf(JSON.stringify(item)), 1);
      }
      return array.map((i) => JSON.parse(i));
    },
    setValueFromChange(event, test, i) {
      switch (test.component) {
        case CourseCheckboxList:
          this.testsForm[i].props.value = this.toggleFromArray(this.testsForm[i].props.value, event);
          break;
        default:
          this.testsForm[i].props.value = event;
          break;
      }
    },
  },
  components: { LoadingIndicator, CoursePageHeader, CourseTestComponent },
};
</script>

<style lang="stylus">
.course-tests {
  display grid
  grid-gap 40px

  .file-upload
  .ipt {
    width 100%
    max-width 520px
  }
}
</style>
