<template>
  <figure class="icon file-icon" v-if="ext">
    <InlineSvg :src="`/static/svg/${error ? 'none' : ext}.svg`" @error="error = true" />
  </figure>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "FileIconComponent",
  props: {
    ext: String,
  },
  data() {
    return {
      error: false,
    };
  },
  components: {
    InlineSvg,
  },
};
</script>

<style lang="stylus">
.file-icon {
  width 60px
  height 60px
}
</style>
