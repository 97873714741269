<template>
  <div class="modal-body alert-modal">
    <div class="alert-modal__icon">
      <IconComponent name="MessageSuccessIcon" v-if="type === 'success'" />
      <IconComponent name="MessageErrorIcon" v-else />
    </div>
    <div v-if="type === 'error'" class="alert-modal__point">
      Для прохождения нужно набрать минимум {{ point | plural("балл", "балла", "баллов") }}
    </div>
    <div class="alert-modal__title">{{ title }}</div>
    <div class="alert-modal__subtitle">{{ subtitle }}</div>
    <button type="button" class="btn btn--main btn--md" @click="handleClick">{{ buttonTitle }}</button>
  </div>
</template>
<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "AlertModal",
  props: {
    type: {
      type: String,
      default() {
        return "success";
      },
    },
    title: {
      type: String,
      default() {
        return "";
      },
    },
    subtitle: {
      type: String,
      default() {
        return "";
      },
    },
    buttonTitle: {
      type: String,
      default() {
        return "Закрыть";
      },
    },
    point: {
      type: Number,
    },
    callback: {
      type: Function,
    },
  },
  methods: {
    handleClick() {
      this.callback();
      this.$emit("close");
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.alert-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background var(--white)
  padding: 50px
  border-radius 15px
  width 100%
  max-width 588px
  z-index 100

  &__icon {
    display flex
    flex-shrink 0
    margin-bottom 44px
    justify-content: center;

    .icon {
      width 100px
      height 100px

      svg {
        width 100%
        height 100%
      }
    }
  }

  &__title {
    font-weight: bold;
    font-size: 2.25em;
    line-height: 44px;
    color: var(--black);
  }

  &__subtitle {
    font-weight: normal;
    font-size: 1em;
    line-height: 140%;
    color: var(--black);
    margin 10px 0 30px
  }
}
</style>
