<template>
  <figure class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9496 11.9994L10.8086 10.1417V13.8572L13.9496 11.9994ZM14.8226 12.6449C15.3136 12.3549 15.3136 11.6439 14.8226 11.3539L10.9406 9.05795C10.4406 8.76195 9.80859 9.12295 9.80859 9.70395V14.2949C9.80859 14.8759 10.4406 15.2369 10.9406 14.9409L14.8226 12.6449Z"
        fill="#323232"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 3.5C4.61914 3.5 3.5 4.61914 3.5 6V18C3.5 19.3809 4.61914 20.5 6 20.5H18C19.3809 20.5 20.5 19.3809 20.5 18V6C20.5 4.61914 19.3809 3.5 18 3.5H6ZM2.5 6C2.5 4.06686 4.06686 2.5 6 2.5H18C19.9331 2.5 21.5 4.06686 21.5 6V18C21.5 19.9331 19.9331 21.5 18 21.5H6C4.06686 21.5 2.5 19.9331 2.5 18V6Z"
        fill="#323232"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "CoursePlayIcon",
};
</script>
