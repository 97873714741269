var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-open__header"},[_c('span',{staticClass:"module-open__title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"module-open__nav"},[(_vm.havePrev)?_c('button',{staticClass:"btn btn--outline btn--sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('change', {
          type: 'lesson',
          direction: -1,
        })}}},[_vm._v("\n      Предыдущий урок\n    ")]):_vm._e(),(_vm.haveNext)?_c('button',{staticClass:"btn btn--outline btn--sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('change', {
          type: 'lesson',
          direction: 1,
          lesson_id: _vm.id,
        })}}},[_c('span',[_vm._v("Следующий урок")])]):_vm._e(),(!_vm.haveNext)?_c('button',{staticClass:"btn btn--outline btn--sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('change', {
          type: 'finish',
          lesson_id: _vm.id ? _vm.id : null,
        })}}},[_c('span',[_vm._v("Список модулей")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }