<template>
  <div class="module-open__header">
    <span class="module-open__title">{{ title }}</span>
    <div class="module-open__nav">
      <button
        type="button"
        v-if="havePrev"
        class="btn btn--outline btn--sm"
        @click="
          $emit('change', {
            type: 'lesson',
            direction: -1,
          })
        "
      >
        Предыдущий урок
      </button>
      <button
        type="button"
        v-if="haveNext"
        class="btn btn--outline btn--sm"
        @click="
          $emit('change', {
            type: 'lesson',
            direction: 1,
            lesson_id: id,
          })
        "
      >
        <span>Следующий урок</span>
      </button>
      <button
        type="button"
        v-if="!haveNext"
        class="btn btn--outline btn--sm"
        @click="
          $emit('change', {
            type: 'finish',
            lesson_id: id ? id : null,
          })
        "
      >
        <span>Список модулей</span>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "CoursePageHeader",
  props: {
    id: Number,
    havePrev: Boolean,
    haveNext: Boolean,
    showTypeToggle: {
      type: Boolean,
      default() {
        return false;
      },
    },
    passingTest: Boolean,
    title: String,
  },
};
</script>
<style lang="stylus">
.module-open__header{
  & .btn{
    border 1px solid
  }
}
</style>
