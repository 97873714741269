<template>
  <label
    class="file-upload"
    :class="{
      'file-upload--disabled': disabled,
      'file-upload--highlight': highlight && !disabled,
    }"
    @dragenter="dragOver"
    @dragleave="dragLeave"
    @dragover="dragOver"
    @drop="drop"
  >
    <span class="file-upload__list" v-if="files.length && !drag && !highlight">
      <a
        :href="file.url"
        class="file-upload__item"
        v-for="(file, i) in files"
        :key="i"
        target="_blank"
        download
      >
        <!--<span class="file-upload__loading">-->
        <!--  <LoaderIcon class="loading-rotate" />-->
        <!--</span>-->
        <FileIconComponent :ext="file.type" />
        <span>{{ file.origin_name }}</span>
      </a>
    </span>
    <span class="file-upload__placeholder" v-else>
      <FileIconComponent ext="zip" />
      <LoadingIndicator title="Загрузка файлов" v-if="loading" />
      <template v-else>
        <span>Для загрузки файла перетащите его в данную область.</span>
        <span class="file-upload__or"><span></span><span>Или</span><span></span></span>
        <span class="btn btn--small btn--gray-outline--thin btn--gray-outline">Выбрать файлы</span>
      </template>
    </span>
    <input type="file" ref="input" v-show="false" @change="change" multiple :disabled="loading || disabled" />
  </label>
</template>

<script>
import FileIconComponent from "components/FileIconComponent.vue";
import FILE_UPLOAD from "gql/mutations/FileUpload.graphql";
import LoadingIndicator from "components/LoadingIndicator.vue";

export default {
  name: "FileUploadComponent",
  props: {
    uploaded: Array,
    disabled: Boolean,
  },
  data() {
    return {
      drag: false,
      highlight: false,
      over: false,
      loading: false,
      files: [],
      dragTimeout: null,
      highlightTimeout: null,
    };
  },
  watch: {
    uploaded() {
      this.files = this.uploaded;
    },
  },
  mounted() {
    this.files = this.uploaded;
    document.addEventListener("dragover", (event) => {
      event.preventDefault();
      event.stopPropagation();
    });
    document.addEventListener("dragover", (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.drag = true;
    });
    document.addEventListener("dragleave", (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.debouncedDelayedSetOfDrag(false);
    });
  },
  methods: {
    debouncedDelayedSetOfDrag(value) {
      clearTimeout(this.dragTimeout);
      this.dragTimeout = setTimeout(() => {
        this.drag = value;
      }, 200);
    },
    debouncedDelayedSetOfHighlight(value) {
      clearTimeout(this.highlightTimeout);
      this.highlightTimeout = setTimeout(() => {
        this.highlight = value;
      }, 200);
    },
    dragLeave(event) {
      event.preventDefault();
      event.stopPropagation();
      this.debouncedDelayedSetOfHighlight(false);
    },
    dragOver(event) {
      event.preventDefault();
      event.stopPropagation();
      this.highlight = true;
    },
    drop(event) {
      event.preventDefault();
      const dt = event.dataTransfer;
      if (dt) {
        this.loading = true;
        this.debouncedDelayedSetOfHighlight(false);
        this.debouncedDelayedSetOfDrag(false);
        this.upload(dt.files);
      }
    },
    change(event) {
      if (!this.loading && !this.disabled) {
        this.loading = true;
        if (event.target.files.length) {
          this.upload(event.target.files);
        }
      }
    },
    upload(files) {
      this.$apollo.provider.defaultClient
        .mutate({
          mutation: FILE_UPLOAD,
          variables: {
            files: files,
          },
          context: {
            headers: {
              Authorization: "Bearer " + this.$store.state.cookies["apollo-token"],
            },
          },
        })
        .then(({ data }) => {
          if (data.FileUpload && data.FileUpload.length) {
            this.files.push(...data.FileUpload);
          }
          this.files.forEach((file) => {
            delete file.__typename;
          });
          this.loading = false;
          this.$emit("change", this.files);
        });
    },
  },
  components: {
    LoadingIndicator,
    // LoaderIcon,
    FileIconComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.file-upload {
  display flex
  align-items center
  justify-content center
  flex-flow row wrap
  width 100%
  min-height 200px
  background var(--gray_light_pro)
  border: 1px dashed var(--border_color);
  border-radius var(--radius)
  padding 38px 25px
  gap 24px
  transition border-color var(--transition)
  cursor pointer

  &__or {
    width 100%
    display flex
    align-items center
    justify-content center
    gap 15px
    font-size: 0.875em;
    line-height: 17px;
    text-align: center;
    color: var(--black);

    span:last-child,
    span:first-child {
      max-width 100px
      width 100%
      height 1px
      background var(--border_color)
    }
  }

  &--highlight
  &:hover {
    padding 37px 24px
    border-width 2px
    border-color var(--main_color);
  }

  &--highlight &__btn
  &:hover &__btn {
    background var(--white)
    border-color var(--main_color)
    color var(--main_color)
  }

  &:active &__btn {
    transform scale(0.97)
  }

  &__list {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 24px;
  }

  &__loading {
    absolute left top
    width 100%
    height 100%
    display flex
    justify-content center
    align-items center
    background var(--main_dark-t4);
    border-radius var(--radius)

    .icon {
      width 40px
      height 40px
      svg(var(--white))
    }
  }

  &__item {
    width 92px
    display flex
    flex-direction column
    justify-content flex-start
    align-items center
    font-weight: normal;
    font-size: 0.75em;
    line-height: normal;
    text-align: center;
    color: var(--main_gray);
    white-space: pre-wrap;
    word-wrap: break-word;
    gap 12px
    position relative

    span {
      width 100%
    }

    .icon {
      height 60px
      max-width 60px
    }
  }

  &__placeholder {
    display flex
    flex-direction column
    align-items center
    justify-content center
    gap 8px
    font-weight: normal;
    font-size: 0.8750em;
    line-height: 20px;
    text-align: center;
    color: var(--main_default_dark);
    user-select none
  }
}
</style>
